import React, {useEffect, useState} from 'react';
import ednaImage from "../images/edna.webp"
import feedImage from "../images/feed.webp"
import closetItemsImage from "../images/closet_items.webp"
import closetItemImage from "../images/closet_item.webp"
import closetOutfitsImage from "../images/closet_outfits.webp"
import closetOutfitsEdnaImage from "../images/closet_outfits_edna.webp"
import appScreenshot from '../app_screenshot.webp'
import downloadImage from '../app_store_download.svg'
import {SocialIcon} from 'react-social-icons'
import {GooglePlayButton, AppStoreButton} from "react-mobile-app-button";

const APKUrl = "https://play.google.com/store/apps/details?id=com.tolu.fashion&hl=en_US";
const AppStoreUrl = "https://apps.apple.com/ca/app/runwayy/id6466562995";
const InstagramUrl = "https://www.instagram.com/runwayyapp/";

const MovingImageRow = ({ images, duration = 30, reverse = false }) => {
    const animationStyle = {
        animationDuration: `${duration}s`,
        animationDirection: reverse ? 'reverse' : 'normal'
    };

    return (
        <div className="overflow-hidden whitespace-nowrap">
            <div
                className="inline-block animate-marquee"
                style={animationStyle}
            >
                {images.concat(images).map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt}
                        className="inline-block w-1/3 sm:w-1/4 md:w-1/5 lg:w-1/6 h-full object-cover mx-2 sm:mx-3 md:mx-4"
                    />
                ))}
            </div>
        </div>
    );
};

const Home = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = windowWidth < 640; // Assuming 640px as the breakpoint for mobile

    const images = [
        {src: ednaImage, alt: 'Dining table'},
        {src: closetItemsImage, alt: 'Building exterior'},
        {src: feedImage, alt: 'Yellow room'},
        // {src: closetOutfitsEdnaImage, alt: 'Nightclub scene'},
        {src: closetOutfitsImage, alt: 'Abstract blue'},
        {src: closetItemImage, alt: 'Abstract blue'},
    ];

    return (
        <div className="bg-black text-white min-h-screen flex flex-col justify-between">
            <div className="container mx-auto px-4 py-8 flex-1 flex flex-col justify-center">
                <div className="mb-8">
                    <MovingImageRow images={images}/>
                </div>

                <div className="text-center my-12">
                    <h2 className="text-4xl font-bold mb-4">Runwayy</h2>
                    <p className="text-xl">
                        Your style assistant.
                    </p>
                </div>

                <div className="flex flex-col items-center mt-8 space-y-6 md:space-y-0 md:flex-row md:justify-between">
                    <div className="order-2 md:order-1 mt-2 md:mt-0">
                        <SocialIcon url={InstagramUrl}/>
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 order-1 md:order-2">
                        <AppStoreButton
                            url={AppStoreUrl}
                            theme={"dark"}
                            className="w-48 h-14"
                        />
                        <GooglePlayButton
                            url={APKUrl}
                            theme={"dark"}
                            className="w-48 h-14"
                            height={isMobile ? 75 : undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;